import ApiService from "./api.service";
const InspectionBookService = {
  GetList(data) {
    return ApiService.post(`/InspectionBook/GetList`, data);
  },
  Get(id) {
    if (id == 0 || id == undefined || id == null || id == "") {
      return ApiService.get(`/InspectionBook/Get`);
    } else {
             return ApiService.get(`/InspectionBook/Get/${id}`);
           }
  },
  GetByRequestId(id) {
    return ApiService.get(`/InspectionBook/GetByRequestId/${id}`);
  },
  UpdateContractor(data) {
    if (data.id == 0) {
      return ApiService.post(`/InspectionBook/CreateByContractor`, data);
    } else {
      return ApiService.post(`/InspectionBook/UpdateByContractor`, data);
    }
  },
  Update(data) {
    if (data.id == 0) {
      return ApiService.post(`/InspectionBook/Create`, data);
    } else {
      return ApiService.post(`/InspectionBook/Update`, data);
    }
  },
  Send(data) {
    return ApiService.post(`/InspectionBook/Send`, data);
  },
};
export default InspectionBookService;
